<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-700px">
            <div>
                <p class="title">인바운드 처리 가이드</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view stageClass-table">
                    <tbody>
                        <tr>
                            <th width="90">대원칙</th>
                            <td>
                                <div class="txt1">문의 온 고객사 정보와 FU 사항은 모두 고객사BD에서 관리하고 히스토리 관리하는 것을 원칙으로 한다.  </div>
                            </td>
                        </tr>
                        <tr>
                            <th width="90">1</th>
                            <td>
                                <div class="txt1">인바운드 담당이 고객사 배정하면서 고객사DB와 연동시킨다. </div>
                            </td>
                        </tr>
                        <tr>
                            <th width="90">2</th>
                            <td>
                                <div class="txt1">처리담당 (고객사 배정 받은 사람)이 고객사 컨택 후 고객 정보(성함 확인, 휴대폰 번호, 이메일 주소 등)를 명함 보내기 한다. </div>
                            </td>
                        </tr>
                        <tr>
                            <th width="90">3</th>
                            <td>
                                <div class="txt1">컨설팅 상담한 내용은 인바운드 게시판 댓글이 아닌 고객사DB창에 FU사항을 연속적으로 기록한다. </div>
                            </td>
                        </tr>
                        <tr>
                            <th width="90">4</th>
                            <td>
                                <div class="txt1">OPEN 성공하지 못한 고객사는 분기별/반기별로 지속 컨택하여 1단계 고객사로 만드는 것을 목표로 관리한다. </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'StatusSMPPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>