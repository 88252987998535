<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <StatusSMPPopup v-if="pop.isShowPop" @close="pop.isShowPop=false"></StatusSMPPopup>
        <CarrotTitle title="전략마케팅 처리현황(인바운드 게시판)">
            <div class="btn-ibbox title-btnbox float-right">
                <button class="btn-default" @click="pop.isShowPop=true">인바운드 처리 가이드</button>
            </div>
            - 전화, E-mail 등 모든 경로로 들어오는 신규 마케팅 관련 사항을 등록해 주세요.<br>
            - 회사명을 클릭하면 상세내용을 보실 수 있습니다.<br>
            - 고객사DB와 연결된 경우, 회사보기 버튼이 노출됩니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div v-if="bbs.isMounted==true">
                    <div class="mt-70 mb-20">
                        <table class="table-row table-serach">
                            <tbody>
                                <tr>
                                    <th width="180">접수일자</th>
                                    <td>
                                        <carrot-date-picker v-model="bbs.rsdate" class="dp-inblock w-110px mr-5"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="bbs.redate" class="dp-inblock w-110px ml-5"></carrot-date-picker>
                                    </td>
                                    <th width="180">처리일자</th>
                                    <td>
                                        <carrot-date-picker v-model="analysis.sdate" class="dp-inblock w-110px mr-5"></carrot-date-picker>
                                        ~
                                        <carrot-date-picker v-model="analysis.edate" class="dp-inblock w-110px ml-5"></carrot-date-picker>
                                    </td>
                                </tr>
                                <tr>
                                    <th width="180">본부/팀</th>
                                    <td>
                                        <carrot-dept v-model="bbs.office" class="float-left w-120px"></carrot-dept>
                                        <carrot-team :idx_office="bbs.office" v-model="bbs.team" class="float-left w-120px ml-5"></carrot-team>
                                    </td>
                                    <th width="180">회사명</th>
                                    <td>
                                        <input type="text" v-model.trim="bbs.company" @keyup.enter="bbs.doInit" class="w-90per">
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button @click="bbs.doInit" class="btn-default float-right mt-10 ml-10">검색</button>
                      <router-link :to="{ name:'customerManagement-inboundDashboard' }"><button class="btn-default float-right h-30px mt-10 ml-10"> 인바운드 DashBoard</button></router-link>


                        <div class="clear"></div>
                    </div>

                    <div class="mt-20 mb-20">
                        <select v-model="bbs.orderby" @change="bbs.doSearch()" class="w-150px float-left mr-10">
                            <option value="rdatedesc">접수일자 최신순</option>
                            <option value="pdatedesc">처리일자 최신순</option>
                        </select>
                        <select v-model="bbs.state" @change="bbs.doSearch()" class="w-150px float-left mr-10">
                            <option value="">진행상황 전체</option>
                            <option value="REQUEST">대기중</option>
                            <option value="PROCESS">처리중</option>
                            <option value="OPEN">OPEN</option>
                            <option value="FAIL">FAIL</option>
                            <option value="STOP">중단</option>
                        </select>

                        <router-link :to="{ name:'customerManagement-statusSMPAdd' }"><button class="btn-default float-right h-30px ml-10"> 등록</button></router-link>
                      <button class="btn-default float-right h-30px" @click="bbs.downExcel"> 게시판 현황 엑셀 다운로드 </button>

                      <div class="clear"></div>
                    </div>
                    <table class="table-col">
                        <colgroup>
                            <col width="60">
                            <col width="120">
                            <col width="*">
                            <col width="170">
                            <col width="120">
                            <col width="120">
                            <col width="120">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>접수일자</th>
                                <th>회사명</th>
                                <th>본부/팀 배정</th>
                                <th>과정종류</th>
                                <th>배정일자</th>
                                <th>진행상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.receipt_date }}</td>
                                <td>
                                    <span @click="bbs.showSMP(irow.idx)" class="btn-view">{{ irow.companyname }}
                                        <span v-if="bbs.chkNewBtn(irow.receipt_date)" class="my-box-list-new">N</span>
                                        ({{ irow.cnt_comment }})
                                    </span>
                                    <button v-if="irow.idx_company > 0" @click="bbs.goCustomerDBView(irow.idx_company)" class="btn-default ml-10 display-inline" >회사보기</button>
                                </td>

                              <td>{{ irow.team_name?irow.team_name:irow.office_name }}</td>
                              <td>{{ irow.program }}</td>
                                <td>{{ irow.process_date }}</td>
                                <td>
                                    <span v-if="irow.state=='REQUEST'" class="color-1">{{ irow.state_txt }}</span>
                                    <span v-if="irow.state=='PROCESS'">{{ irow.state_txt }}</span>
                                    <span v-if="irow.state=='OPEN'" class="txt-blue">{{ irow.state_txt }}</span>
                                    <span v-if="irow.state=='FAIL'" class="txt-gray">{{ irow.state_txt }}</span>
                                    <span v-if="irow.state=='STOP'">{{ irow.state_txt }}</span>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="7">전략마케팅 처리현황이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
// import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'
import StatusSMPPopup from '@/components/popup/customerManagement/StatusSMPPopup.vue'

export default {
    layout:"customerManagement",
    components: {
        CarrotDatePicker,
        CarrotDept,
        CarrotTeam,
        StatusSMPPopup,
    },
    setup() {
        // const router = useRouter();
        const toast  = useToast();

        const pop = reactive({
            // Popup 노출 여부
            isShowPop : false,
        });

        const analysis = reactive({
            company : "",
            sdate   : "",
            edate   : "",

            total : {},
            list  : [],

            doSearch : () => {
                let params = { 
                    company : analysis.company,
                    sdate   : analysis.sdate,
                    edate   : analysis.edate
                };

                axios.get("/rest/customermgr/analysisSMP", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        analysis.total = res.data.total;
                        analysis.list  = res.data.list;

                        bbs.isMounted = true;
                        bbs.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        const bbs = reactive({
            isMounted : false,

            page : 1,
            rows : 50,

            rsdate : "",
            redate : "",
            psdate : "",
            pedate : "",
            office : 0,
            team   : 0,
            company: "",

            orderby  : "rdatedesc",
            state    : "",
            category : "",

            list : [], total : 0,

            showSMP : (idx) => {
                window.open(`/customerManagement/statusSMPView/${idx}`, '_blank');
                // router.push({ 
                //     name:'customerManagement-statusSMPView-idx', 
                //     params: { idx: idx } 
                // });
            },

            goCustomerDBView : (idx) => {
                window.open(`/customerManagement/customerDBViewTab1/${idx}`, '_blank');
            },

            doInit : () => {
                bbs.page = 1;
                bbs.doSearch();
            },

            downExcel : () => {
              let url = "https://api.carrotians.net";
              if ( window.location.host.includes("local")) {
                url = "http://local.intranet.mxm.kr";
              } else if(window.location.host.includes("stage")){
                url = "https://stageapi.carrotians.net";
              }
              url = url +"/excel/smp_list?rsdate="+ bbs.rsdate + "&redate=" + bbs.redate + "&psdate=" + bbs.psdate + "&pedate=" + bbs.pedate
                  + "&office=" + bbs.office+ "&team=" + bbs.team+ "&company=" + bbs.company+ "&state=" + bbs.state+ "&category=" + bbs.category+ "&orderby=" + bbs.orderby
              ;
              window.open(url, '_blank').focus();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;

                let params = {
                    page : bbs.page,
                    rows : bbs.rows,

                    rsdate : bbs.rsdate,
                    redate : bbs.redate,
                    psdate : bbs.psdate,
                    pedate : bbs.pedate,

                    office : bbs.office,
                    team   : bbs.team,
                    company : bbs.company,

                    state    : bbs.state,
                    category : bbs.category,
                    orderby  : bbs.orderby
                };

                axios.get("/rest/customermgr/getSMPList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            chkNewBtn : (date) => {
                var nowDate = new Date();
                var tmpDate = new Date(date);
                if(nowDate - tmpDate < 86400000 ) return true;
                else return false;
            },
        });

        onMounted(() => {
            // Mounted

            let nd = new Date();
            analysis.sdate = nd.getFullYear() + "-01-01";
            analysis.edate = nd.getFullYear() + "-12-31";

            analysis.doSearch();
        });

        return {analysis, bbs, pop};
    }
}
</script>

<style lang="scss" scoped>
.display-inline{
    display: inline;
}
</style>